import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PortableText } from "@portabletext/react";
import Layout from "../components/Layout";
import ContributingFor from "../components/ContributingFor";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";
import { getImageDimensions } from "@sanity/asset-utils";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder({
  projectId: "p5dzjrg7",
  dataset: "production",
});

const url =
  typeof window !== "undefined"
    ? window.location.pathname.replace("/", "")
    : "";

const Image = ({ value, width, height, url }) => {
  return (
    <img
      className="shadow-md"
      src={builder.image(value?.asset?._ref)}
      alt={value?.alt ? value?.alt : url}
      width={width}
      height={height}
      style={{
        marginBottom:
          value.attribution && value.attribution.length > 0 ? 0 : 15,
        marginTop: 15,
      }}
    />
  );
};

const AboutPage = ({ data: { sanityAboutPage } }) => {
  return (
    <Fragment>
      <Layout>
        <section className={"bg-black pb-16 pt-10 flex items-center "}>
          <div className="container">
            <div className="relative lg:h-[560px]">
              <div className="relative lg:h-[453px] lg:w-[640px]  xl:h-[553px] xl:w-[740px]">
                {/*<StaticImage src={"../images/about/4.png"}*/}
                {/*			 alt={"About"}/>*/}
                <GatsbyImage
                  alt={"About"}
                  image={
                    sanityAboutPage?.heroSection?.thumbnail?.asset
                      ?.gatsbyImageData
                  }
                />
              </div>
              <div className="about-gradient py-[19px] px-[50px] rounded-2xl max-w-[686px] lg:absolute lg:top-[160px] lg:-right-12 xl:-right-16 2xl:right-[170px]">
                <p className="text-3xl sm:text-[48px] leading-10 sm:leading-[61px] font-semibold text-white font-lora">
                  <span className="font-normal about-text">
                    {sanityAboutPage?.heroSection?.title?.split(" ")?.shift()}
                  </span>{" "}
                  {sanityAboutPage?.heroSection?.title
                    ?.split(" ")
                    ?.slice(1)
                    ?.join(" ")}
                </p>
                <p className="text-sm sm:text-[18px] leading-[25px] font-normal text-white mt-[11px] font-lora">
                  {sanityAboutPage?.heroSection?.caption}
                </p>
              </div>
            </div>
          </div>
        </section>
        <ContributingFor
          contributionSection={sanityAboutPage.contributionsSection}
        />
        {sanityAboutPage?.contentBuilder.map((item, index) => {
          if (item?._type === "lightContent") {
            return (
              <section
                className={`mx-10 px-5 md:px-[122px] py-[60px] bg-[#F9F9F9] ${
                  index === 0 && "rounded-t-[50px]"
                } ${
                  index === sanityAboutPage?.contentBuilder.length - 1 &&
                  "rounded-b-[50px] mb-[90px]"
                }`}
              >
                <div
                  className={
                    "font-lora text-[18px] leading-[23px] max-w-[915px] mx-auto"
                  }
                  data-aos="fade-in"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="800"
                  data-aos-easing="ease-in"
                >
                  <PortableText
                    value={item?._rawContent}
                    components={{
                      block: {
                        normal: ({ children }) => (
                          <p className="mb-[18px]">{children}</p>
                        ),
                      },
                      types: {
                        image: ({ value }) => {
                          const { width, height } = getImageDimensions(value);
                          return (
                            <>
                              <div className={`flex justify-center blog-img`}>
                                <Image
                                  value={value}
                                  height={height}
                                  width={width}
                                  url={url}
                                />
                              </div>
                            </>
                          );
                        },
                      },
                    }}
                  />
                </div>
              </section>
            );
          } else
            return (
              <section className={"bg-black md:p-5 p-10"}>
                <div
                  className={
                    "flex justify-center items-center gap-10 flex-wrap"
                  }
                  data-aos="fade-in"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="800"
                  data-aos-easing="ease-in"
                >
                  <p className={"text-white max-w-[523px] font-lora text-2xl"}>
                    {item?.content}
                  </p>
                  <GatsbyImage
                    image={item?.thumbnail?.asset?.gatsbyImageData}
                    alt={"About"}
                  />
                </div>
              </section>
            );
        })}
      </Layout>
      <Footer />
    </Fragment>
  );
};

export default AboutPage;

export const Head = ({ data: { sanityAboutPage } }) => (
  <SEO metadata={sanityAboutPage?.pageMetadata || {}} />
);

export const query = graphql`
  query {
    sanityAboutPage {
      title
      heroSection {
        title
        caption
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
      contributionsSection {
        title
        contributions {
          alt
          caption
          attribution
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
      contentBuilder {
        ... on SanityDarkContent {
          _key
          _type
          thumbnail {
            asset {
              gatsbyImageData(placeholder: BLURRED, height: 350)
            }
          }
          content
        }
        ... on SanityLightContent {
          _key
          _type
          _rawContent
        }
      }
      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
